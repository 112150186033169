.DataElement {
  display: flex;
  flex-direction: column;

  &.invert {
    flex-direction: column-reverse;
  }

  p:first-of-type {
    font-size: 16px;
    line-height: 26px;
    color: var(--grey600);
  }

  p:last-of-type {
    margin-top: 8px;
    line-height: 1.63;
  }

  &:not(.equal-size) p:last-of-type {
    font-size: 20px;
    color: var(--grey800);
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
