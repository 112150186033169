@use 'shared/src/styles/constants' as *;

$gauge-size: 220px;

.gauge-wrapper {
  position: relative;
  width: $gauge-size;
  height: $gauge-size;
  background-color: var(--baseWhite);
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: $shadow-16-down;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: $gauge-size * 2;
    height: $gauge-size;
  }

  p {
    color: var(--grey600);
    font-size: 14px;

    &.percentage-value {
      color: var(--grey800);
      font-weight: bold;
      font-size: 36px;
      margin-bottom: 10px;
    }
  }

  &.loading {
    .loading-mask {
      position: absolute;
      background-color: var(--baseWhite);
      height: 180px;
      width: 180px;
      top: 10;
      left: 10;
      border-radius: 50%;
    }
  }
}
