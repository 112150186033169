.MuiButtonBase-root.MuiFab-root.scroll-to-top-fab {
  position: fixed;
  bottom: 1.33rem;
  right: 1.33rem;
  width: 64px;
  height: 64px;

  opacity: 0;
  visibility: hidden;
  transition-duration: 200ms, 200ms, 0;
  transition-property: opacity, visibility;
  transition-delay: 0, 0, 200ms;
  pointer-events: none;

  &.visible {
    cursor: pointer;
    opacity: 1;
    visibility: visible;
    transition-duration: 200ms, 200ms, 0;
    transition-property: opacity, visibility;
    transition-delay: 200ms, 200ms, 0;
    pointer-events: all;
  }
}
