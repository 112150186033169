@use 'shared/src/styles/constants' as *;

.app {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr min-content;
  margin: 0 auto;
  max-width: 640px;
  min-height: 100vh;
}

.device-data-wrapper {
  overflow-x: hidden;
  padding-top: 64px;
  background-color: var(--blue100);

  .MuiDivider-root {
    border-color: var(--grey100);
  }

  &.error {
    padding-top: 0;

    + .fox-footer {
      display: none;
    }
  }

  section {
    padding: 0 16px;

    &.gauge-section {
      padding-bottom: 40px;
    }

    &.device-data-section {
      padding-bottom: 30px;
    }

    &.last-next-metering-section {
      padding: 32px 16px;
      p {
        font-size: 16px;
        line-height: 26px;
      }

      .MuiGrid-root.MuiGrid-item {
        &:first-child {
          padding-right: 16px;
        }

        &:last-child {
          padding-left: 16px;
        }
      }
    }

    &.metering-data-section {
      padding: 0;

      .DataElement {
        margin: 0;
        padding: 32px 16px 8px 16px;
      }
    }

    &.error {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      h2 {
        margin: 32px 0;
      }

      .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 125px;
        height: 125px;
        border-radius: 50%;
        color: var(--red600);
        box-shadow: $shadow-16-down;
        .FoxIcon {
          font-size: 64px;
        }
      }
    }

    table {
      width: 100%;
      background-color: var(--baseWhite);

      thead {
        th {
          max-width: 40px;
          font-weight: 600;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 16px 8px;

          &:first-of-type {
            padding-left: 16px;
          }
          &:last-of-type {
            padding-right: 16px;
          }
        }
      }

      tbody {
        td {
          padding: 8px;

          &:first-of-type {
            padding-left: 16px;
          }

          &:last-of-type {
            padding-right: 16px;
          }
        }

        tr {
          &.invalid {
            td {
              color: var(--grey200);
            }
          }
        }
      }
    }

    .DataElement {
      &.main {
        p:first-of-type {
          font-size: 16px;
          margin-top: 8px;
        }

        p:last-of-type {
          font-size: 24px;
          color: var(--baseBlack);
          margin-top: 0;
        }
      }
    }
  }
}
